.crossIcon {
  left: 92px;
  top: -8px;
  cursor: pointer;
}

.copyIcon {
  border: 2px solid gray;
  border-radius: 20px;
  padding: 4px;
  background: gray;
  color: white;
}

.product-number-input input[type="number"]::-webkit-inner-spin-button,
.product-number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  border: none !important;
}

.product-number-input input:focus {
  outline: none !important;
  border: none !important;
}

.product-number-input button:focus {
  outline: none !important;
  border: none !important;
}
