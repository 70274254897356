@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "ObjectSans";
    src: url("fonts/objectSans/ObjectSans-Regular.otf") format("otf");
  }
  @font-face {
    font-family: "Objective";
    src: url("fonts/objective/Objective-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: "circular-reg";
    src: url("fonts/circular/CircularStd-Book.woff2") format("woff2");
  }
  @font-face {
    font-family: "circular-bold";
    src: url("fonts/circular/CircularStd-Bold.woff2") format("woff2");
  }
}

.full-screen__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  margin: auto;
  outline: 0;
  background-color: rgba(79, 79, 79, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
